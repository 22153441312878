import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'
import NotFound404 from '../views/notFound404.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'CLN Plåtslageri'
        }
    },
    {
        path: '/om-oss',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
            title: 'CLN Plåtslageri - Om oss & kontakt'
        }
    },
    {
        path: '/referenser',
        name: 'References',
        component: () => import(/* webpackChunkName: "references" */ '../views/References.vue'),
        meta: {
            title: 'CLN Plåtslageri - Referenser'
        }
    },
    {
        path: '*',
        name: 'NotFound404',
        component: NotFound404,
        meta: {
            title: 'CLN Plåtslageri'
        }
    }
]

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    }
    else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }
    next();
});

export default router
