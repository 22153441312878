




import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MailTo extends Vue {
  @Prop({default: 'info@clnab.se'})
  private email!: string;
}
Vue.component('mail-to', MailTo);
